import { QueryClient, dehydrate } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import ResumeApplyProvider from "src/context/ResumeApplyProvider"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import useIndex, {
  fetchPopularCategories,
  fetchTopCompanies,
  getBenefits,
  getMainPageV2
} from "src/server/queryHooks/useIndex"
import { fetchNaturalPerson } from "src/server/queryHooks/usePersonApi"
import useVacancy, { getLangsSkillsCountries } from "src/server/queryHooks/useVacancy"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import LandingGenerator from "src/views/pages/index/helpers/landingGenerator"
import SearchBox from "src/views/pages/index/SearchBox/SearchBox"
import { SearchProvider } from "src/views/pages/index/SearchBoxNew/hooks/SearchContext"
import SearchBoxContainer from "src/views/pages/index/SearchBoxNew/SearchBoxContainer"
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import ConsentModal from "src/views/components/ConsentsModals/ConsentModal"
import ConsentExternalModal from "src/views/components/ConsentsExternalModal/ConsentExternalModal"
import ShowAgainConsentModal from "src/views/components/ConsentsModals/ShowAgainConsentModal"

import useConsents from "src/server/queryHooks/useConsents"
import Cookies from "js-cookie"
import { checkConsentTime } from "src/utils/helpers/consents/checkConsentTime/checkConsentTime"
import useUser from "src/hooks/useUser"

const pageMeta = {
  title: "MyJobs.ge - Your Ultimate Job Search Platform",
  desc: "MyJobs.ge connects job seekers with top employers, providing a streamlined and intuitive platform for discovering and applying to job opportunities. Explore a diverse range of positions, enhance your career prospects, and find your ideal job with ease.",
  img: "/images/myjobs_logo.svg" // You can replace this with the actual path to your image
}

//** Meta
const PageMeta = dynamic(() => import("src/views/pages/main/PageMeta"), { ssr: true })
const IndexLayout = dynamic(() => import("src/views/layouts/IndexLayout"), { ssr: false })
const LandingBanner = dynamic(() => import("src/views/pages/index/LandingBanner"), { ssr: false })

const Index = ({ AccessToken }: { AccessToken: string }) => {

  const { mainPageV2Query, popularCategoriesQuery, topCompaniesQuery } = useIndex()
  const { userConsentsQuery } = useConsents()
  const { userExternalConsentsQuery } = useConsents()
  const { savedVacanciesQuery } = useVacancy()

  const { connectedUser } = useUser()

  const [showModal, setShowModal] = useState<boolean | string>(false)

  useEffect(() => {
    const savedTime = sessionStorage.getItem("consentTime")

    console.log(connectedUser)
    if (!connectedUser && savedTime) {
      sessionStorage.removeItem("consentTime")
    }
  }, [connectedUser])

  useEffect(() => {
    if (userConsentsQuery.isSuccess && userConsentsQuery.data) {
      if (!userConsentsQuery.data.expanded) {
        setShowModal("ModifyShowAgainModal")
      }

      if (userConsentsQuery.data.expanded) {
        setShowModal("ConsentGeneralModal")
      }
    }
  }, [userConsentsQuery.isSuccess, userConsentsQuery.data])

  useEffect(() => {
    const interval = setInterval(() => {
      const consentTime = checkConsentTime()

      if (consentTime) {
        setShowModal("ExternalProductsConsentModal")
        sessionStorage.removeItem("consentTime")
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const savedTime = sessionStorage.getItem("consentTime")

    if (
      !userConsentsQuery.data &&
      !savedTime &&
      userExternalConsentsQuery.isSuccess &&
      userExternalConsentsQuery.data
    ) {
      setShowModal("ExternalProductsConsentModal")
    }
  }, [userExternalConsentsQuery.isSuccess, userExternalConsentsQuery.data])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <PageMeta meta={pageMeta} />
      {userConsentsQuery.data && showModal === "ConsentGeneralModal" && (
        <ConsentModal isOpen={true} onClose={handleCloseModal} consentData={userConsentsQuery.data} />
      )}

      {userConsentsQuery.data && showModal === "ModifyShowAgainModal" && (
        <ShowAgainConsentModal consentData={userConsentsQuery.data} />
      )}

      {userExternalConsentsQuery.data && showModal === "ExternalProductsConsentModal" && (
        <ConsentExternalModal isOpen={true} onClose={handleCloseModal} consentData={userExternalConsentsQuery.data} />
      )}

      {/* Apply to vacancy modal Modal */}
      <IndexLayout showPeople>
        <div className="mx-4 mt-6 flex max-w-[1030px] flex-col items-center gap-3 pb-[40px] md:mx-auto md:mt-[100px] md:gap-7 md:pb-[80px]">
          <SearchProvider>
            <SearchBox />
          </SearchProvider>
        </div>
        <LandingBanner AccessToken={AccessToken} />
        {/* <RecentlySearchedButton className="no-scrollbar ml-4 w-11/12 md:ml-8" /> */}
        <ResumeApplyProvider>
          {mainPageV2Query.data && topCompaniesQuery.data && popularCategoriesQuery.data && (
            <LandingGenerator
              mainPageData={mainPageV2Query.data}
              publicCategories={popularCategoriesQuery.data}
              topCompanies={topCompaniesQuery.data}
            />
          )}
        </ResumeApplyProvider>
      </IndexLayout>
    </>
  )
}

const queryClient = new QueryClient()

export async function getServerSideProps(ctx: any) {
  const AccessToken = ctx.req?.cookies?.AccessToken ?? ""

  await Promise.all([
    queryClient.prefetchQuery([QUERY_KEYS.MAIN_PAGE_V2], getMainPageV2, {
      staleTime: CacheTime.THIRTY_SECONDS,
      cacheTime: CacheTime.THIRTY_SECONDS
    }),
    queryClient.prefetchQuery([QUERY_KEYS.DATA_FOR_VACANCIES], getLangsSkillsCountries, {
      staleTime: CacheTime.MAIN_PAGE,
      cacheTime: CacheTime.MAIN_PAGE
    }),
    queryClient.prefetchQuery([QUERY_KEYS.PUBLIC_POPULAR_CATEGORIES], fetchPopularCategories, {
      staleTime: CacheTime.MAIN_PAGE,
      cacheTime: CacheTime.MAIN_PAGE
    }),
    queryClient.prefetchQuery([QUERY_KEYS.TOP_COMPANIES], fetchTopCompanies),
    queryClient.prefetchQuery([QUERY_KEYS.GET_BENEFITS], getBenefits, {
      staleTime: CacheTime.MAIN_PAGE,
      cacheTime: CacheTime.MAIN_PAGE
    }),
    queryClient.prefetchQuery([QUERY_KEYS.GET_NATURAL_PERSON_DATA], () => fetchNaturalPerson(ctx.req), {
      staleTime: CacheTime.MAIN_PAGE,
      cacheTime: CacheTime.MAIN_PAGE
    })
  ])

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      AccessToken,
      ...await serverSideTranslations(ctx.locale || 'ka', ["common"]),
    }
  }
}

export default Index
